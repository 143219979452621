<template>
  <f-card>
    <v-row>
      <v-col cols="12">
        <v-combobox
          v-model="model.value"
          hide-selected
          label="Emails"
          multiple
          outlined
          dense
          persistent-hint
          small-chips
          deletable-chips
          hide-details
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Pressione <kbd>enter</kbd> para adicionar um novo.
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
  </f-card>
</template>

<script>
export default {
  props: {
    settingList: Array,
    settingName: String,
    loading: Boolean
  },
  data() {
    return {
      model: {
        value: []
      }
    }
  },
  created() {
    this.model.value = this.settingList
  },
  watch: {
    'model.value': function (value) {
      this.$nextTick(() => {
        this.$emit('save', value)
      })
    }
  }
}
</script>

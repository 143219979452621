<template>
	<div>
		<f-default-header
			:title="$t('pages.settings.title')"
			:subtitle="subtitle" />

		<f-list-table
			v-if="!loading"
			:setting-name="settingName"
			:setting-list="settingList"
			@save="onSave" />

		<f-actions-btn-holder>
			<v-btn elevation="0" large @click="back">
				{{ $t("labels.btnBack") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import FListTable from "../../components/settings/support/List.vue";

export default {
	components: {
		FListTable,
	},
	created() {
		this.getData();
	},
	data() {
		return {
			settingList: [],
			loading: true,
		};
	},
	computed: {
		settingName() {
			return this.$route.params.name;
		},
		subtitle() {
			const labels = {
				supportEmails: this.$t("pages.settings.subtitle.mktSupportEmails"),
				mktContactEmails: this.$t("pages.settings.subtitle.mktContactEmails"),
				orderEmails: this.$t("pages.settings.subtitle.mktOrdersEmails"),
			};
			return labels[this.settingName];
		},
	},
	methods: {
		async getData() {
			this.loading = true;
			const { body } = await this.$store.dispatch("option/list", {
				name: this.settingName,
			});
			if (body) {
				this.settingList = body;
			}
			this.loading = false;
		},
		async onSave(payload) {
			if (JSON.stringify(this.settingList) !== JSON.stringify(payload)) {
				await this.$store.dispatch("option/save", {
					name: this.settingName,
					payload,
				});
				this.$store.commit("snackbar/show", {
					content: this.$t("snackbar.saveMktSupportEmail"),
					type: "success",
				});
			}
		},
		back() {
			this.$router.push({
				name: "settings",
			});
		},
	},
};
</script>
